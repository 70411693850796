/**
* 업무구분: 고객
* 화 면 명: MSPFS100M
* 화면설명: CDD고객정보관리1165
* 작 성 일: 2023.03.08
* 작 성 자: 이태흥
* 수정일 : 2024-08-20 강현철 사유 : ASR240701140 / 고객명 띄어쓰기 허용
* 수정일 : 2025-04-08 강현철 사유 : ASR250201079 / (사랑온) 마케팅동의서 화면 및 양식 수정
*/
<template>
  <ur-page-container class="fts" :show-title="false" :title="pHeaderObj.title" :topButton="true">
    <!-- header start -->
    <fs-header ref="fsHeader" :propObj="pHeaderObj"></fs-header>
    <!-- header end -->

    <!-- main start -->
    <main class="fts-main">
      <ur-box-container direction="column" alignV="start">
        <div class="input-top">
          <div class="left">
            <div class="wrap-input row">
              <label class="emphasis"> 실명번호 종류 </label>
              <mo-dropdown ref="cob_rname_gb" class="w150" :items="lv_commCode.code211" v-model="lv_cob_rname_gb.value" :disabled="lv_cob_rname_gb.disabled" />
            </div>

            <div class="wrap-input row">
              <label class="emphasis"> 실명번호 </label>
              <mo-text-field
                ref="ed_rname_no1"
                class="w80"
                :class="lv_ed_rname_no.error ? 'error' : ''"
                v-model="lv_ed_rname_no.value1"
                :disabled="lv_ed_rname_no.disabled"
                @keyup="fn_CustNameSearch($event)"
                placeholder="앞 6자리"
                maxlength="6"
                type="number"
              />

              <m-trans-key-input
                v-if="isMobile && isMtrans"
                ref="ed_rname_no2"
                class="w80"
                :class="lv_ed_rname_no.error ? 'error' : ''"
                v-model="lv_ed_rname_no.value2"
                type="numberMax7"
                start="1"
                end="-1"
                dialog="Y"
                :isClear="lv_isClear"
                @masked-txt="fn_SetMaskedTxt"
                :disabled="lv_ed_rname_no.disabled"
              />
              <mo-text-field
                v-else
                ref="ed_rname_no2"
                class="w80"
                type="password"
                :class="lv_ed_rname_no.error ? 'error' : ''"
                v-model="lv_ed_rname_no.value2"
                :disabled="lv_ed_rname_no.disabled"
                maxlength="7"
                @keyup="fn_CustNameSearch($event)"
                @paste="fn_CustNameSearch($event, 'P')"
                placeholder="뒤 7자리"
              />
            </div>

            <div class="wrap-input row">
              <label class="emphasis"> 성명 </label>
              <mo-text-field
                ref="ed_cust_nm"
                class="w130"
                :class="lv_ed_cust_nm.error ? 'error' : ''"
                v-model="lv_ed_cust_nm.value"
                :disabled="lv_ed_cust_nm.disabled"
                clearable
                @keyup.enter="fn_CddSearch"
                placeholder="입력하세요"
              />
            </div>
          </div>
          <div class="right">
            <div class="wrap-button row">
              <mo-button class="clear" @click="fn_Init()"> 초기화 </mo-button>
              <mo-button primary class="inquiry" :disabled="lv_btn_search" @click="fn_CddSearch()"> 조회 </mo-button>
            </div>
          </div>
        </div>
      </ur-box-container>

      <ur-box-container direction="column" alignV="start">
        <div class="wrap-table-title">
          <h2 class="table-title">본인</h2>
        </div>
        <div class="wrap-table">
          <table class="table col-type w50p col1634">
            <!-- <colgroup>
              <col width="12%">
              <col width="38%">
              <col width="12%">
              <col width="38%">
            </colgroup> -->
            <tbody>
              <tr>
                <th>
                  <span class="emphasis"> 성명 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <!--ASR240701140 / 고객명 띄어쓰기 허용 -->
                    <mo-text-field
                      ref="cust_nm"
                      class="input-long"
                      :class="lv_cust_nm.error ? 'error' : ''"
                      v-model="lv_cust_nm.value"
                      @blur="fn_Keyup({ objName: 'lv_cust_nm', keyName: 'value' })"
                      :disabled="lv_cust_nm.disabled"
                      clearable
                    />
                  </div>
                </td>
                <th>
                  <span class="emphasis"> 국적 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-dropdown
                      :items="lv_commCode.code981"
                      :class="lv_fnd_dmcl_cntry_cd.error ? 'error' : ''"
                      v-model="lv_fnd_dmcl_cntry_cd.value"
                      :disabled="lv_fnd_dmcl_cntry_cd.disabled"
                      placeholder="선택하세요"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th rowspan="5">
                  <span class="emphasis"> 주소 </span>
                </th>
                <td>
                  <!-- 주소1 -->
                  <div class="wrap-input row">
                    <mo-dropdown
                      :items="lv_commCode.code215"
                      :class="lv_addr_main_ctadr.error ? 'error' : ''"
                      v-model="lv_addr_main_ctadr.value"
                      :disabled="lv_addr_main_ctadr.disabled"
                      placeholder="선택하세요"
                    />
                  </div>
                </td>
                <th>
                  <span class="emphasis"> 거주국가 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-dropdown
                      ref="bfcer_dmcl_cntry_cd"
                      :items="lv_commCode.code981"
                      :class="lv_bfcer_dmcl_cntry_cd.error ? 'error' : ''"
                      v-model="lv_bfcer_dmcl_cntry_cd.value"
                      :disabled="lv_bfcer_dmcl_cntry_cd.disabled"
                      placeholder="선택하세요"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <!-- 주소2 -->
                  <div class="wrap-input row">
                    <mo-text-field class="input-long" :class="lv_addr.error ? 'error' : ''" v-model="lv_addr.zipNum" :disabled="lv_addr.disabled" />
                    <mo-button class="btn-zip" @click="fn_OpenPopup(0)"> 우편번호 </mo-button>
                  </div>
                </td>
                <th class="bl-ddd">
                  <span class="emphasis"> 전화번호 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field
                      ref="tel_no1"
                      class="input-long"
                      :class="lv_tel_no.error ? 'error' : ''"
                      v-model="lv_tel_no.value1"
                      :disabled="lv_tel_no.disabled"
                      maxlength="3"
                      type="number"
                      @input="fn_clear('number')"
                    />
                    <mo-text-field
                      ref="tel_no2"
                      class="input-long"
                      :class="lv_tel_no.error ? 'error' : ''"
                      v-model="lv_tel_no.value2"
                      :disabled="lv_tel_no.disabled"
                      maxlength="4"
                      type="number"
                      @input="fn_clear('number')"
                    />
                    <mo-text-field
                      ref="tel_no3"
                      class="input-long"
                      :class="lv_tel_no.error ? 'error' : ''"
                      v-model="lv_tel_no.value3"
                      :disabled="lv_tel_no.disabled"
                      maxlength="4"
                      type="number"
                      @input="fn_clear('number')"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <!-- 주소3 -->
                  <div class="wrap-input row">
                    <mo-text-field class="input-long" :class="lv_addr.error ? 'error' : ''" v-model="lv_addr.value1" :disabled="lv_addr.disabled" clearable />
                    <mo-text-field class="w90" :class="lv_addr.error ? 'error' : ''" v-model="lv_addr.value2" :disabled="lv_addr.disabled" clearable />
                  </div>
                </td>
                <th class="bl-ddd">
                  <span> 휴대폰 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-dropdown
                      ref="addr2"
                      :class="lv_phone_no.error ? 'error' : ''"
                      :items="lv_commCode.code370"
                      v-model="lv_phone_no.value1"
                      :disabled="lv_phone_no.disabled"
                      placeholder="선택하세요"
                    />
                    <mo-text-field class="input-long" :class="lv_phone_no.error ? 'error' : ''" v-model="lv_phone_no.value2" :disabled="lv_phone_no.disabled" maxlength="4" type="number" />
                    <mo-text-field class="input-long" :class="lv_phone_no.error ? 'error' : ''" v-model="lv_phone_no.value3" :disabled="lv_phone_no.disabled" maxlength="4" type="number" />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <!-- 주소4 -->
                  <div class="wrap-input row">
                    <mo-text-field class="input-long" :class="lv_addr.error ? 'error' : ''" v-model="lv_addr.value3" :disabled="lv_addr.disabled" clearable />
                  </div>
                </td>
                <th class="bl-ddd">
                  <span> E-mail 주소 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field class="input-email01" :class="lv_email_addr.error ? 'error' : ''" v-model="lv_email_addr.value1" :disabled="lv_email_addr.disabled" clearable />
                    <span class="ico-between-email"> @ </span>
                    <mo-text-field class="input-email02" :class="lv_email_addr.error ? 'error' : ''" v-model="lv_email_addr.value2" :disabled="lv_email_addr.disabled" clearable />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <!-- 주소5 -->
                  <div class="wrap-input row">
                    <mo-text-field class="input-long" :class="lv_addr.error ? 'error' : ''" v-model="lv_addr.value4" :disabled="lv_addr.disabled" clearable />
                  </div>
                </td>
                <th class="bl-ddd">
                  <span class="emphasis"> 계좌신규목적 </span>
                </th>
                <td>
                  <mo-dropdown
                    :class="lv_accn_new_pps_cd.error ? 'error' : ''"
                    :items="lv_commCode.code214"
                    v-model="lv_accn_new_pps_cd.value"
                    :disabled="lv_accn_new_pps_cd.disabled"
                    placeholder="선택하세요"
                  />
                </td>
              </tr>
              <tr>
                <th>
                  <span class="emphasis"> 입출금 거래목적 </span>
                </th>
                <td>
                  <mo-dropdown
                    :class="lv_rdamt_dl_pps_cd.error ? 'error' : ''"
                    :items="lv_commCode.code213"
                    v-model="lv_rdamt_dl_pps_cd.value"
                    :disabled="lv_rdamt_dl_pps_cd.disabled"
                    placeholder="선택하세요"
                  />
                </td>
                <th>
                  <span class="emphasis"> 업종 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field class="input-wMax input-long" :class="lv_ed_indcl_sort_cd.error ? 'error' : ''" v-model="lv_ed_indcl_sort_cd.value" :disabled="lv_ed_indcl_sort_cd.disabled" />
                    <mo-button class="btn-input" @click="fn_OpenPopup(1)"> 업종코드 </mo-button>
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span class="emphasis"> 직업구분 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-dropdown :class="lv_cob_job_gb.error ? 'error' : ''" :items="lv_commCode.code217" v-model="lv_cob_job_gb.value" :disabled="lv_cob_job_gb.disabled" placeholder="선택하세요" />
                  </div>
                </td>
                <th>
                  <span :class="lv_job_name.emphasis ? 'emphasis' : ''"> 회사명 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field
                      ref="job_name"
                      class="input-long"
                      :class="lv_job_name.error ? 'error' : ''"
                      v-model="lv_job_name.value"
                      :disabled="lv_job_name.disabled"
                      clearable
                      @input="fn_clear('job')"
                    />
                  </div>
                </td>
              </tr>

              <!-- 국적이 한국 아닐경우 표시 -->
              <tr v-if="lv_fnd_dmcl_cntry_cd.value != '' && lv_fnd_dmcl_cntry_cd.value != 'KR'">
                <th>
                  <span class="emphasis"> 영문명 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field ref="cust_nm_eng" class="input-long" :class="lv_cust_nm_eng.error ? 'error' : ''" v-model="lv_cust_nm_eng.value" :disabled="lv_cust_nm_eng.disabled" clearable />
                  </div>
                </td>
                <th>
                  <span class="emphasis"> 여권번호 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field ref="passport" class="input-long" :class="lv_passport.error ? 'error' : ''" v-model="lv_passport.value" :disabled="lv_passport.disabled" clearable />
                  </div>
                </td>
              </tr>
              <tr v-if="lv_fnd_dmcl_cntry_cd.value != '' && lv_fnd_dmcl_cntry_cd.value != 'KR'">
                <th>
                  <span class="emphasis"> 생년월일 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <!-- <mo-dropdown
                      :class="lv_cal_birth_dt.error ? 'error' : ''"
                      :items="job"
                      v-model="lv_cal_birth_dt.value"
                      :disabled="lv_cal_birth_dt.disabled"
                      placeholder="선택하세요"
                    /> -->
                    <div class="picker row">
                      <mo-date-picker :class="lv_cal_birth_dt.error ? 'error' : ''" v-model="lv_cal_birth_dt.value" :bottom="false" />
                    </div>
                  </div>
                </td>
                <th>
                  <span class="emphasis"> 성별 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <!-- <mo-dropdown
                      :class="lv_gender.error ? 'error' : ''"
                      :items="gender"
                      v-model="lv_gender.value"
                      :disabled="lv_gender.disabled"
                      placeholder="선택하세요"
                    /> -->
                    <mo-radio-wrapper :items="gender" v-model="lv_gender.value" class="row" :class="lv_gender.error ? 'error' : ''" />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ur-box-container>

      <ur-box-container>
        <div class="wrap-button button-page-bottom row">
          <div class="left">
            <mo-button v-if="lv_isProcess && lv_call_screen_id != 'MSPFS307M'" point size="large" @click="fn_GoNextPage()" :disabled="lv_btn_next"> 다음 </mo-button>
          </div>
          <mo-button point size="large" @click="fn_Update()" :disabled="lv_btn_update">수정</mo-button>
          <mo-button primary size="large" @click="fn_Insert(0)" :disabled="lv_btn_insert">등록</mo-button>
        </div>
      </ur-box-container>
    </main>
    <!-- main end -->

    <!-- alert modal popup -->
    <fs-alert-popup ref="alertPopup" :popupObj="pAlertPopupObj"></fs-alert-popup>

    <!-- popup100 -->
    <msp-fs-100p ref="popup100" @fs-alert-popup="fn_AlertPopup" @fs-popup-callback="fn_Popup_CallBack" :popupObj="pPopup100Obj"></msp-fs-100p>

    <!-- popup101 -->
    <msp-fs-101p ref="popup101" @fs-alert-popup="fn_AlertPopup" @fs-popup-callback="fn_Popup_CallBack" :popupObj="pPopup101Obj"></msp-fs-101p>
  </ur-page-container>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import FSCommUtil from '~/src/ui/fs/comm/FSCommUtil'
import FSMessageUtil from '~/src/ui/fs/comm/FSMessageUtil'
import FSServiceUtil from '~/src/ui/fs/comm/FSServiceUtil'
import FSCodeUtil from '~/src/ui/fs/comm/FSCodeUtil'
import FSBizUtil from '~/src/ui/fs/comm/FSBizUtil'
import FSInfoUtil from '~/src/ui/fs/comm/FSInfoUtil'

import FSHeader from '~/src/ui/fs/comm/FSHeader' // header 영역 (공통)
import FSAlertPopup from '~/src/ui/fs/comm/FSAlertPopup' // Alert 팝업 (공통)
import FSDocForm from '@/ui/fs/comm_elst/FSDocFormUtil' // 전자서식 (공통)

import MSPFS100P from '~/src/ui/fs/MSPFS100P' // 우편번호 (공통)
import MSPFS101P from '~/src/ui/fs/MSPFS101P' // 업종코드

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: 'MSPFS100M',
  screenId: 'MSPFS100M',
  components: {
    'fs-header': FSHeader,
    'fs-alert-popup': FSAlertPopup,
    'msp-fs-100p': MSPFS100P,
    'msp-fs-101p': MSPFS101P
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {
    this.fn_SetCommCode()
  },
  mounted() {
    this.fn_Init()
    this.$bizUtil.insSrnLog('MSPFS100M')

    if (!FSCommUtil.gfn_isNull(this.$route.params.cob_rname_gb)) {
      console.log('!!!! call_screen_id ')
      console.log(this.$route.params.call_screen_id)

      // 계좌신규에서 넘어왔을 경우 예외처리 // 고객 요청으로 기능 사용 안함
      if (!FSCommUtil.gfn_isNull(this.$route.params.call_screen_id)) {
        this.lv_call_screen_id = this.$route.params.call_screen_id
        if (this.$route.params.call_screen_id == 'MSPFS307M' && !FSCommUtil.gfn_isNull(this.$route.params.lv_ed_group_no)) {
          this.lv_ed_group_no = this.$route.params.lv_ed_group_no
        }
      }

      // 그룹신규 화면에서 진입한 경우
      // if( this.$route.params.rmvl_srn_id == 'CU1010' ) {
      setTimeout(() => {
        this.lv_cob_rname_gb.value = this.$route.params.cob_rname_gb
        this.lv_ed_rname_no.value1 = this.$route.params.ed_rname_no[0]
        this.lv_ed_rname_no.value2 = this.$route.params.ed_rname_no[1]

        // 실명번호로 CDD고객조회
        this.fn_CustNameSearch({ key: 'v' })
      }, 100)
      // }
    }
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      // 공통 객체
      pHeaderObj: {
        title: 'CDD고객정보관리',
        step: 2
      },
      pAlertPopupObj: {},
      lv_ed_cust_no: {}, // 고객번호
      lv_commCode: {},
      lv_masked_val: '', // 마스크 변수 체크

      isMobile: window.vue.getStore('deviceState').getters.getIsMobile,
      isMtrans: this.getStore('fsStore').getters.getState.isMtrans, // 보안키패드 적용 여부
      lv_call_screen_id: '', // 전페이지 스크린 ID (현재 MSPFS307M 만 사용 확인)
      lv_ed_group_no: '', // 전페이지 그룹번호 (현재 MSPFS307M 만 사용 확인)

      // 팝업 객체
      pPopup100Obj: {},
      pPopup101Obj: {},
      netxtFunc: '',
      // 검색조건
      lv_cob_rname_gb: {}, // 실명번호 종류
      lv_ed_rname_no: {}, // 실명번호
      lv_ed_cust_nm: {}, // 성명

      // 기본정보
      lv_cust_nm: {}, // 고객명
      lv_addr_main_ctadr: {}, // 주연락처주소코드
      lv_fnd_dmcl_cntry_cd: {}, // 국적
      lv_bfcer_dmcl_cntry_cd: {}, // 거주국가

      lv_addr: {}, // 주소
      lv_tel_no: {}, // 전화번호
      lv_phone_no: {}, // 휴대폰번호
      lv_email_addr: {}, // email주소
      lv_accn_new_pps_cd: {}, // 계좌신규목적

      lv_rdamt_dl_pps_cd: {}, // 입출금거래목적
      lv_ed_indcl_sort_cd: {}, // 업종
      lv_cob_job_gb: {}, // 직업구분
      lv_job_name: {}, // 회사명

      // 국적이 한국 아닐경우 표시
      lv_cust_nm_eng: {}, // 영문명
      lv_passport: {}, // 여권번호
      lv_cal_birth_dt: {}, // 생년월일
      lv_gender: {}, // 성별

      // button
      lv_btn_search: false, // 조회
      lv_btn_insert: true, // 등록
      lv_btn_update: true, // 수정
      lv_btn_next: true, // 다음
      lv_isInsert: true, // 등록/수정 여부 판단

      // INSERT/UPDATE DATASET(AS-IS: ds_cu1165)
      lv_submit_data: {},
      lv_isClear: false, // 보안키패드 초기화

      /***********************************************************************************
       * F10570125 : CDD에등록된고객명조회
       * F10570117 : CDD고객정보조회
       * F10570126 : CDD고객정보등록
       * F10570127 : CDD고객정보수정
       ***********************************************************************************/
      // EAI(PO) 호출 객체
      eaiCommObj: FSCommUtil.gfn_eaiCommObj()
    }
  },
  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
   ***********************************************************************************/
  computed: {
    lv_isProcess() {
      return this.getStore('fsStore').getters.getState.isProcess
    },

    gender() {
      let rtn = []
      rtn.push({ value: 'M', text: '남' })
      rtn.push({ value: 'F', text: '여' })
      return rtn
    }
  },
  /***********************************************************************************
   * watch 함수 정의 영역                                                         *
   ***********************************************************************************/
  watch: {
    // 국적 선택
    'lv_fnd_dmcl_cntry_cd.value': {
      handler(after, before) {
        let t_popupObj = {}

        if (!FSCommUtil.gfn_isNull(after) && !FSCommUtil.gfn_isNull(before)) {
          if (after == 'KR') {
            t_popupObj = { content: '국적을 한국으로 선택하셨습니다.' }
          } else {
            t_popupObj = { content: '국적을 한국 外 나라로 선택하셨습니다.\n영문명,여권번호,생년월일,성별을 정확히 입력해 주시기 바랍니다.' }
          }
          this.fn_AlertPopup(0, t_popupObj)
        }
      },
      deep: true
    },

    // 직업구분 선택
    'lv_cob_job_gb.value': {
      handler(after, before) {
        if (after == '01') {
          this.lv_job_name.emphasis = true
        } else {
          this.lv_job_name.emphasis = false
        }
      },
      deep: true
    },

    // 프로세스 체크
    lv_isProcess: {
      handler(after, before) {
        console.log('lv_isProcess > ', after)
        this.fn_Init()
      },
      deep: true,
      immediate: true
    }
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    /******************************************************************************
     * Function명 : fn_SetMaskedTxt
     * 설명       : 보안키패드 체크
     ******************************************************************************/
    fn_SetMaskedTxt(val) {
      this.lv_masked_val = val
      if (this.lv_masked_val.length >= 7) {
        this.$nextTick(() => {
          this.fn_CustNameSearch({ key: 'v' })
        })
      }
    },

    /******************************************************************************
     * Function명 : fn_SetCommCode, fn_SetCommCodeResult
     * 설명       : 공통코드 세팅
     ******************************************************************************/
    fn_SetCommCode() {
      console.log('fn_SetCommCode...')

      let params = [
        { cId: '211', dayChkYn: 'N' },
        { cId: '213', dayChkYn: 'N' },
        { cId: '214', dayChkYn: 'N' },
        { cId: '215', dayChkYn: 'N' },
        { cId: '217', dayChkYn: 'N' },
        { cId: '370', dayChkYn: 'N' },
        { cId: '981', dayChkYn: 'N' }
      ]
      FSCodeUtil.commCodeList(this, params, this.fn_SetCommCodeResult)
    },
    fn_SetCommCodeResult(pResult) {
      // ASR250201079 / (사랑온) 마케팅동의서 화면 및 양식 수정
      pResult.code981.sort((a, b) => {
        if (a.text > b.text) {
          return 1
        } else if (a.text < b.text) {
          return -1
        }
        return 0
      })

      this.lv_commCode = pResult

      // 실명번호종류 코드 재설정
      let t_codeList = []
      pResult.code211.forEach((item) => {
        if (item.value == '01' || item.value == '04' || item.value == '05') {
          t_codeList.push(item)
        }
      })
      this.lv_commCode.code211 = t_codeList
    },

    /******************************************************************************
     * Function명 : fn_Init
     * 설명       : 화면 초기화
     ******************************************************************************/
    fn_Init() {
      console.log('[MSPFS100M] fn_Init...')
      console.log('isProcess :: ' + this.lv_isProcess)

      // 검색조건
      this.lv_cob_rname_gb = FSCommUtil.gfn_dataSet(0, '01') // 실명번호 종류
      // this.lv_ed_rname_no         = FSCommUtil.gfn_dataSet(1, '', false, '', 'ed_rname_no') // 실명번호 입력
      this.lv_ed_rname_no = {
        error: false,
        disabled: false,
        value1: '',
        value2: ''
      }

      this.lv_ed_cust_nm = FSCommUtil.gfn_dataSet(1, '', false, '', 'ed_cust_nm') // 성명 입력

      this.lv_btn_search = false // 조회
      this.lv_btn_insert = true // 등록
      this.lv_btn_update = true // 수정
      this.lv_btn_next = true // 다음

      // 기본정보
      this.lv_cust_nm = FSCommUtil.gfn_dataSet(1, '', true, '', 'cust_nm') // 고객명
      this.lv_addr_main_ctadr = FSCommUtil.gfn_dataSet(1, '', true) // 주연락처주소코드
      this.lv_fnd_dmcl_cntry_cd = FSCommUtil.gfn_dataSet(1, '', true) // 국적
      this.lv_bfcer_dmcl_cntry_cd = FSCommUtil.gfn_dataSet(1, '', true) // 거주국가

      this.lv_accn_new_pps_cd = FSCommUtil.gfn_dataSet(0, '', true) // 계좌신규목적
      this.lv_rdamt_dl_pps_cd = FSCommUtil.gfn_dataSet(0, '', true) // 입출금거래목적
      this.lv_ed_indcl_sort_cd = FSCommUtil.gfn_dataSet(1, '', true) // 업종
      this.lv_cob_job_gb = FSCommUtil.gfn_dataSet(0, '', true) // 직업구분
      this.lv_job_name = FSCommUtil.gfn_dataSet(1, '', true, '', 'job_name') // 회사명

      // 주소
      this.lv_addr = {
        error: false,
        disabled: true,
        zipNum: '',
        standard: '',
        value1: '',
        value2: '',
        value3: '',
        value4: ''
      }

      // 전화번호
      this.lv_tel_no = {
        error: false,
        disabled: true,
        value1: '',
        value2: '',
        value3: ''
      }

      // 휴대폰번호
      this.lv_phone_no = {
        error: false,
        disabled: true,
        value1: '',
        value2: '',
        value3: ''
      }

      // email주소
      this.lv_email_addr = {
        error: false,
        disabled: true,
        value1: '',
        value2: ''
      }

      // 국적이 한국 아닐경우 표시
      this.lv_cust_nm_eng = FSCommUtil.gfn_dataSet(1, '', false, '', 'cust_nm_eng') // 영문명
      this.lv_passport = FSCommUtil.gfn_dataSet(1, '', false, '', 'passport') // 여권번호
      this.lv_cal_birth_dt = FSCommUtil.gfn_dataSet(1, '') // 생년월일
      this.lv_gender = FSCommUtil.gfn_dataSet(1, '') // 성별

      // 보안키패드 초기화
      this.lv_isClear = !this.lv_isClear ? true : false
    },

    /******************************************************************************
     * Function명 : fn_AlertPopup
     * 설명       : alert popup 호출(공통)
     ******************************************************************************/

    fn_AlertPopup(type, pPopupObj) {
      if (!FSCommUtil.gfn_isNull(pPopupObj)) {
        // 공통 얼럿 팝업 오브젝트 초기화
        FSInfoUtil.initAlertPopObj(type, this.pAlertPopupObj, pPopupObj)
      }
      this.$refs.alertPopup.fn_Open()
    },

    /******************************************************************************
     * Function명 : fn_OpenPopup
     * 설명       : popup 컴포넌트 호출
     * 타입(type) : 0: 우편번호 / 1: 업종코드
     ******************************************************************************/
    fn_OpenPopup(type) {
      switch (type) {
        case 0:
          this.$refs.popup100.fn_Open()
          break
        case 1:
          this.$refs.popup101.fn_Open()
          break
      }
    },

    /******************************************************************************
     * Function명 : fn_Popup_CallBack
     * 설명       : popup 컴포넌트에서 callback
     * 타입(type) : 0: 우편번호 / 1: 업종코드
     ******************************************************************************/
    fn_Popup_CallBack(type, pData) {
      switch (type) {
        case 0:
          console.log('우편번호 callback...')
          this.lv_addr.error = false // 우편번호 validation 초기화
          this.lv_addr.zipNum = pData.vZipcd_head + pData.vZipcd_tail // 우편번호
          this.lv_addr.value1 = pData.vZipcd_ubmyundong // 우편번호주소
          this.lv_addr.value2 = pData.v_bldg_no // 건물번호
          this.lv_addr.value3 = pData.v_stand_addr // 상세주소암호화
          this.lv_addr.value4 = pData.v_adtn_addr // 부가주소
          this.lv_addr.standard = pData.v_addr_stdzt_yn // 표준화여부

          break
        case 1:
          console.log('업종코드 callback...')
          this.lv_ed_indcl_sort_cd.error = false // 업종코드 validation 초기화
          this.lv_ed_indcl_sort_cd.value = pData.vjob_cd // 업종코드
          break
        case 2:
          break
      }
    },

    /******************************************************************************
     * Function명 : fn_RecordConfirm
     * 설명       : 녹취 확인
     ******************************************************************************/
    fn_RecordConfirm() {
      console.log('fn_RecordConfirm.....')
      this.$refs.fsHeader.fn_Record()
    },

    /******************************************************************************
     * Function명 : fn_CustNameSearch, fn_CustNameSearchResult
     * 설명       : 실명번호로 CDD고객조회
     ******************************************************************************/
    async fn_CustNameSearch(event, opt) {
      console.log('fn_CustNameSearch.....')
      this.lv_ed_rname_no.error = false
      let ed_rname_no2 = ''
      if (!this.isMobile) {
        if (FSCommUtil.gfn_length(this.lv_ed_rname_no.value1) == 6) {
          if (FSCommUtil.gfn_isNum(event.key) || event.key == 'v' || this.isMobile) {
            this.$refs['ed_rname_no2'].focus()
          }
        }
        ed_rname_no2 = opt == 'P' ? (event.clipboardData || window.clipboardData).getData('text') : this.lv_ed_rname_no.value2
      } else {
        ed_rname_no2 = this.lv_ed_rname_no.value2
      }

      if (FSCommUtil.gfn_length(this.lv_ed_rname_no.value1) + FSCommUtil.gfn_length(ed_rname_no2) >= 13) {
        if (!FSCommUtil.gfn_isNum(this.lv_ed_rname_no.value1)) {
          FSCommUtil.gfn_validate(this, '실명번호의 형식에 맞지 않습니다.')
          this.lv_ed_rname_no.error = true
          this.$refs['ed_rname_no1'].focus()
          return
        }

        if (!this.isMobile) {
          if (!FSCommUtil.gfn_isNum(ed_rname_no2)) {
            FSCommUtil.gfn_validate(this, '실명번호의 형식에 맞지 않습니다.')
            this.lv_ed_rname_no.error = true
            this.$refs['ed_rname_no2'].focus()
            return
          }
        } else {
          if (FSCommUtil.gfn_length(this.lv_masked_val) !== 7) {
            FSCommUtil.gfn_validate(this, '실명번호의 형식에 맞지 않습니다.')
            this.lv_ed_rname_no.error = true
            return
          }
          this.lv_ed_rname_no.error = false
        }

        // 실명번호종류/실명번호 비활성화
        this.lv_cob_rname_gb.disabled = true
        this.lv_ed_rname_no.disabled = true

        if (FSCommUtil.gfn_isNum(event.key) || event.key == 'v' || opt == 'P' || this.isMobile) {
          if (opt == 'P') {
            this.lv_ed_rname_no.value2 = ed_rname_no2
          }

          // 서비스 호출
          this.eaiCommObj.lv_vm = this
          this.eaiCommObj.auth = 'S'
          this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570125_S'
          this.eaiCommObj.params = {
            bfcer_accn_no: '', // 수익증권 계좌번호, Length : 13
            rlnm_no_knd_cd: this.lv_cob_rname_gb.value, // 실명확인번호구분코드, Length : 10
            rlnm_cnf_no_encr: this.lv_ed_rname_no.value1 + ed_rname_no2, // 실명확인번호암호화, Length : 24
            fnd_cust_nm: this.lv_ed_cust_nm.value // 펀드고객명, Length : 100
          }

          FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_CustNameSearchResult)
        }
      }
    },
    fn_CustNameSearchResult(pResultData) {
      console.log('fn_CustNameSearchResult.....')

      let t_data = pResultData.data
      this.lv_isInsert = FSCommUtil.gfn_isNull(FSCommUtil.gfn_trim(t_data.cust_cnf_syst_cust_nm)) // 등록/수정 여부 판단

      if (!this.lv_isInsert) {
        this.lv_ed_cust_nm.value = t_data.cust_cnf_syst_cust_nm // 성명 입력값
        this.lv_ed_cust_nm.disabled = true // 성명 입력값 비활성화
      } else {
        this.$refs['ed_cust_nm'].focus()
      }
    },

    /******************************************************************************
     * Function명 : fn_CddSearch, fn_CddSearchResult, fn_CddSearchFail
     * 설명       : CDD고객정보조회
     ******************************************************************************/
    async fn_CddSearch() {
      console.log('fn_CddSearch.....')

      if (!this.fn_SearchValid()) {
        return
      }

      // 실명확인번호
      let rlnm_cnf_no_encr
      if (!this.isMobile) {
        rlnm_cnf_no_encr = this.lv_ed_rname_no.value1 + this.lv_ed_rname_no.value2
      } else {
        rlnm_cnf_no_encr = this.lv_ed_rname_no.value1 + this.lv_masked_val
      }

      // 65세이상 고령자/80세이상 초고령자 체크(80세 이상일 경우 불가)
      if (!FSBizUtil.gfn_chkOldAge(this, rlnm_cnf_no_encr, this.lv_cob_rname_gb.value, this.fn_AlertPopup)) {
        // return
      }

      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570117_S'
      this.eaiCommObj.params = {
        bfcer_accn_no: '', // 수익증권 계좌번호, Length : 13
        rlnm_no_knd_cd: this.lv_cob_rname_gb.value, // 실명확인번호구분코드, Length : 10
        rlnm_cnf_no_encr: this.lv_ed_rname_no.value1 + this.lv_ed_rname_no.value2, // 실명확인번호암호화, Length : 24, 암호화여부=Y, Column=RRN_ENCR
        fnd_cust_nm: this.lv_ed_cust_nm.value // 펀드고객명, Length : 100
      }

      FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_CddSearchResult)
    },
    fn_CddSearchResult(pResultData) {
      console.log('fn_CddSearchResult....')

      let t_data = pResultData.data
      console.log(t_data)

      // 다음/수정/등록 버튼 초기화
      this.lv_btn_insert = true
      this.lv_btn_update = true
      this.lv_btn_next = true

      // 조회 버튼 비활성화
      this.lv_btn_search = true

      // INSERT/UPDATE DATASET 매핑
      this.lv_submit_data = pResultData.data

      if (this.lv_isInsert && FSCommUtil.gfn_isNull(FSCommUtil.gfn_trim(t_data.cust_cnf_syst_cust_nm))) {
        this.lv_ed_cust_nm.disabled = true // 성명 입력값 비활성화

        this.lv_cust_nm.value = this.lv_ed_cust_nm.value // 성명
        this.lv_cust_nm.disabled = false // 성명 활성화

        this.lv_addr_main_ctadr.value = '1' // 주연락처주소코드
        this.lv_addr_main_ctadr.disabled = false // 주연락처주소코드 활성화

        this.lv_fnd_dmcl_cntry_cd.value = 'KR' // 국적
        this.lv_fnd_dmcl_cntry_cd.disabled = false // 국적 활성화

        this.lv_bfcer_dmcl_cntry_cd.value = 'KR' // 거주국가
        this.lv_bfcer_dmcl_cntry_cd.disabled = false // 거주국가 활성화

        // 전화번호
        this.lv_tel_no.disabled = false

        // 휴대폰
        this.lv_phone_no.value1 = '010'
        this.lv_phone_no.disabled = false

        // email
        this.lv_email_addr.disabled = false

        // 계좌신규목적
        this.lv_accn_new_pps_cd.value = '03'
        this.lv_accn_new_pps_cd.disabled = false

        // 업종
        this.lv_ed_indcl_sort_cd.value = FSCommUtil.gfn_trim(t_data.bfcer_indcl_cd)

        // 입출금거래목적
        this.lv_rdamt_dl_pps_cd.value = '04'
        this.lv_rdamt_dl_pps_cd.disabled = false

        // 직업구분
        this.lv_cob_job_gb.value = '01'
        this.lv_cob_job_gb.disabled = false

        // 회사명
        this.lv_job_name.disabled = false

        // 등록 버튼 활성화
        this.lv_btn_insert = false
      } else {
        this.lv_ed_cust_nm.value = t_data.cust_cnf_syst_cust_nm // 성명 입력값
        this.lv_ed_cust_nm.disabled = true // 성명 입력값 비활성화

        this.lv_cust_nm.value = t_data.cust_cnf_syst_cust_nm // 성명
        this.lv_cust_nm.disabled = true // 성명 비활성화

        this.lv_addr_main_ctadr.value = t_data.bfcer_mn_ctadr_addr_cd // 주연락처주소코드
        this.lv_addr_main_ctadr.disabled = false // 주연락처주소코드 활성화

        this.lv_fnd_dmcl_cntry_cd.value = t_data.fnd_dmcl_cntry_cd // 국적
        this.lv_fnd_dmcl_cntry_cd.disabled = false // 국적 활성화

        this.lv_bfcer_dmcl_cntry_cd.value = t_data.bfcer_dmcl_cntry_cd // 거주국가
        this.lv_bfcer_dmcl_cntry_cd.disabled = false // 거주국가 활성화

        // 휴대폰
        this.lv_phone_no.value1 = t_data.bman_celph_dstno_encr
        this.lv_phone_no.value2 = t_data.bman_celph_tlnum_no_encr
        this.lv_phone_no.value3 = t_data.bman_celph_bkno_sno_encr
        this.lv_phone_no.disabled = false

        // email
        this.lv_email_addr.value1 = t_data.cust_emai_addr_encr
        this.lv_email_addr.value2 = t_data.cust_emai_dm_nm
        this.lv_email_addr.disabled = false

        // 계좌신규목적
        this.lv_accn_new_pps_cd.value = t_data.bfcer_accn_new_pps_cd
        this.lv_accn_new_pps_cd.disabled = false

        // 입출금거래목적
        this.lv_rdamt_dl_pps_cd.value = t_data.bfcer_rdamt_dl_pps_cd
        this.lv_rdamt_dl_pps_cd.disabled = false

        // 업종
        this.lv_ed_indcl_sort_cd.value = t_data.bfcer_indcl_cd

        // 직업구분
        this.lv_cob_job_gb.value = t_data.bfcer_job_sc_cd
        this.lv_cob_job_gb.disabled = false

        // 회사명
        this.lv_job_name.value = t_data.cust_cnf_syst_co_nm
        this.lv_job_name.disabled = false

        // 국적이 한국 아닐경우 표시
        this.lv_cust_nm_eng.value = t_data.cust_cnf_syst_cust_eng_nm // 성명 영문명
        this.lv_passport.value = t_data.cust_psprt_no_encr // 여권번호
        this.lv_cal_birth_dt.value = FSCommUtil.gfn_dateReplace(1, t_data.cust_cnf_syst_birth_mmdd) // 생년월일
        this.lv_gender.value = t_data.cust_cnf_syst_sxds_cd // 성별

        // 자택('1'), 직장('2')
        if (this.lv_addr_main_ctadr.value == '1') {
          // 주소
          this.lv_addr.zipNum = t_data.hom_addr_pstcd01 + t_data.hom_addr_pstcd02 // 우편번호
          this.lv_addr.value1 = t_data.cust_hom_pstcd_addr // 우편번호주소
          this.lv_addr.value2 = t_data.hom_bldg_no_encr // 건물번호
          this.lv_addr.value3 = t_data.cust_hom_dtlad_encr // 상세주소암호화
          this.lv_addr.value4 = t_data.hom_adtn_addr_encr // 부가주소
          this.lv_addr.standard = t_data.hom_addr_stdzt_yn // 표준화여부

          // 전화번호
          this.lv_tel_no.value1 = t_data.hom_dstno_encr
          this.lv_tel_no.value2 = t_data.hom_tlnum_no_encr
          this.lv_tel_no.value3 = t_data.hom_bkno_sno_encr
          this.lv_tel_no.disabled = false
        } else {
          // 주소
          this.lv_addr.zipNum = t_data.jobp_addr_pstcd01 + t_data.jobp_addr_pstcd02 // 우편번호
          this.lv_addr.value1 = t_data.cust_jobp_pstcd_addr // 우편번호주소
          this.lv_addr.value2 = t_data.jobp_bldg_no_encr // 건물번호
          this.lv_addr.value3 = t_data.cust_jobp_dtlad_encr // 상세주소암호화
          this.lv_addr.value4 = t_data.jobp_adtn_addr_encr // 부가주소
          this.lv_addr.standard = t_data.jobp_addr_stdzt_yn // 표준화여부

          // 전화번호
          this.lv_tel_no.value1 = t_data.jobp_dstno_encr
          this.lv_tel_no.value2 = t_data.jobp_tlnum_no_encr
          this.lv_tel_no.value3 = t_data.jobp_bkno_sno_encr
          this.lv_tel_no.disabled = false
        }

        if (this.lv_isInsert) {
          // 등록 버튼 활성화
          this.lv_btn_insert = false

          // 전화번호 활성화
          this.lv_tel_no.disabled = false
        } else {
          // 자택('1'), 직장('2')
          if (this.lv_addr_main_ctadr.value == '1') {
            // 주소
            this.lv_addr.zipNum = t_data.hom_addr_pstcd01 + t_data.hom_addr_pstcd02 // 우편번호
            this.lv_addr.value1 = t_data.cust_hom_pstcd_addr // 우편번호주소
            this.lv_addr.value2 = t_data.hom_bldg_no_encr // 건물번호
            this.lv_addr.value3 = t_data.cust_hom_dtlad_encr // 상세주소암호화
            this.lv_addr.value4 = t_data.hom_adtn_addr_encr // 부가주소
            this.lv_addr.standard = t_data.hom_addr_stdzt_yn // 표준화여부

            // 전화번호
            this.lv_tel_no.value1 = t_data.hom_dstno_encr
            this.lv_tel_no.value2 = t_data.hom_tlnum_no_encr
            this.lv_tel_no.value3 = t_data.hom_bkno_sno_encr
            this.lv_tel_no.disabled = false
          } else {
            // 주소
            this.lv_addr.zipNum = t_data.jobp_addr_pstcd01 + t_data.jobp_addr_pstcd02 // 우편번호
            this.lv_addr.value1 = t_data.cust_jobp_pstcd_addr // 우편번호주소
            this.lv_addr.value2 = t_data.jobp_bldg_no_encr // 건물번호
            this.lv_addr.value3 = t_data.cust_jobp_dtlad_encr // 상세주소암호화
            this.lv_addr.value4 = t_data.jobp_adtn_addr_encr // 부가주소
            this.lv_addr.standard = t_data.jobp_addr_stdzt_yn // 표준화여부

            // 전화번호
            this.lv_tel_no.value1 = t_data.jobp_dstno_encr
            this.lv_tel_no.value2 = t_data.jobp_tlnum_no_encr
            this.lv_tel_no.value3 = t_data.jobp_bkno_sno_encr
            this.lv_tel_no.disabled = false
          }

          // 수정/다음 버튼 활성화
          this.lv_btn_update = false
          this.lv_btn_next = false
        }
      }

      // 고객번호 조회
      let t_data2 = { rlnm_cnf_no_encr: this.lv_ed_rname_no.value1 + this.lv_ed_rname_no.value2 }
      FSInfoUtil.commInfo(this, 0, t_data2, this.fn_searchCnfNoResult)
    },

    /******************************************************************************
     * Function명 : fn_searchCnfNoResult
     * 설명       : 고객번호 조회 callback
     ******************************************************************************/

    fn_searchCnfNoResult(pResultData) {
      console.log('고객정보조회 데이터 ', pResultData)
      if (pResultData.data.error_msg === '809900') {
        this.lv_ed_cust_no = pResultData.data.sy001000_O_00VO[0].bfcer_cust_no
      } else {
        let t_popupObj = {
          content: FSMessageUtil.commMessage([])[pResultData.data.error_msg]
        }
        //this.fn_AlertPopup(0, t_popupObj)
      }
    },

    /******************************************************************************
     * Function명 : fn_SearchValid
     * 설명       : CDD고객조회시 필수값 검증
     ******************************************************************************/
    fn_SearchValid() {
      this.lv_cob_rname_gb.error = false
      this.lv_ed_rname_no.error = false
      this.lv_ed_cust_nm.error = false

      if (FSCommUtil.gfn_length(this.lv_ed_rname_no.value1) != 6 || !FSCommUtil.gfn_isNum(this.lv_ed_rname_no.value1)) {
        FSCommUtil.gfn_validate(this, '실명번호의 형식에 맞지 않습니다.')
        this.lv_ed_rname_no.error = true
        this.$refs['ed_rname_no1'].focus()
        return false
      }

      if (!this.isMobile) {
        if (FSCommUtil.gfn_length(this.lv_ed_rname_no.value2) != 7 || !FSCommUtil.gfn_isNum(this.lv_ed_rname_no.value2)) {
          FSCommUtil.gfn_validate(this, '실명번호의 형식에 맞지 않습니다.')
          this.lv_ed_rname_no.error = true
          this.$refs['ed_rname_no2'].focus()
          return false
        }
      } else {
        if (FSCommUtil.gfn_length(this.lv_masked_val) !== 7) {
          FSCommUtil.gfn_validate(this, '실명번호의 형식에 맞지 않습니다.')
          this.lv_ed_rname_no.error = true
          return false
        }
      }

      if (FSCommUtil.gfn_isNull(this.lv_ed_cust_nm.value.trim())) {
        FSCommUtil.gfn_validate(this, '성명은 필수입력 항목입니다.')
        this.lv_ed_cust_nm.value = this.lv_ed_cust_nm.value.trim()
        this.lv_ed_cust_nm.error = true
        this.$refs['ed_cust_nm'].focus()
        return false
      }
      // ASR240701140 / 고객명 띄어쓰기 허용 (조회용 고객)
      var pObj = { objName: 'lv_ed_cust_nm', keyName: 'value' }
      if (!this.fn_Keyup(pObj)) {
        return false
      }

      return true
    },

    fn_Keyup(pData, mGbn = '') {
      const t_objName = pData.objName
      const t_keyName = pData.keyName
      // ASR240701140 / 고객명 띄어쓰기 허용
      this[t_objName]['error'] = ''
      this[t_objName][t_keyName] = FSCommUtil.gfn_trim(this[t_objName][t_keyName])

      let custGbn = this.lv_cob_rname_gb.value === '01' ? 'ZPER01' : 'ZPER02'
      if (!FSCommUtil.isCustNameValidation(this[t_objName][t_keyName], custGbn)) {
        if (mGbn !== 'I') {
          FSCommUtil.gfn_validate(this, '고객명이 유효하지 않습니다. 확인 후 다시 입력해주세요.')
        }
        this[t_objName]['error'] = 'error'
        this.$refs[this[t_objName].ref].focus()
        return false
      }
      return true
    },
    /******************************************************************************
     * Function명 : fn_Update, fn_UpdateResult
     * 설명       : CDD고객정보수정
     ******************************************************************************/
    async fn_Update() {
      console.log('fn_Update....')

      // 입력/선택 필수값 검증
      if (!this.fn_SubmitVaild()) {
        return
      }

      // 수정 DATASET 초기화
      this.fn_ClearSubmitData()

      // 수정 DATASET 매핑
      this.fn_SetSubmitData()

      // 서비스 호출
      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.auth = 'U'
      this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570127_S'
      this.eaiCommObj.params = this.lv_submit_data

      FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_UpdateResult)
    },

    fn_UpdateResult(pResultData) {
      console.log('fn_UpdateResult....')
      let lv_Vm = this
      let t_type = 0
      let t_popupObj = {
        cancel: false,
        confirm: true,
        content: '',
        confirmFunc: '',
        confirmData: ''
      }
      let t_data = pResultData.data

      // CDD고객정보수정 성공 메세지 = 809901
      if (t_data.error_msg == '809901') {
        if (this.lv_isProcess) {
          t_type = 7
          t_popupObj.confirmFunc = lv_Vm.fn_ReportPrint

          if (FSCommUtil.gfn_isNull(this.lv_ed_cust_no)) {
            t_popupObj.confirmData = {
              fnd_cust_nm: this.lv_ed_cust_nm.value,
              rlnm_cnf_no_encr: this.lv_ed_rname_no.value1,
              bman_celph_dstno_encr: this.lv_phone_no.value1,
              bman_celph_tlnum_no_encr: this.lv_phone_no.value2,
              bman_celph_bkno_sno_encr: this.lv_phone_no.value3,
              cust_emai_addr_encr: this.lv_email_addr.value1,
              cust_emai_dm_nm: this.lv_email_addr.value2
            }
          } else {
            t_popupObj.confirmData = {
              bfcer_cust_no: this.lv_ed_cust_no
            }
          }
          if (this.lv_call_screen_id == 'MSPFS307M') {
            t_popupObj.content = ['CDD 수정이 완료되었습니다. 계좌신규 화면으로 이동합니다.']
          } else {
            t_popupObj.content = ['CDD 수정이 완료되었습니다. 고객등록 화면으로 이동합니다.']
          }
          this.netxtFunc = function() {
            lv_Vm.fn_GoNextPage()
          }
        } else {
          t_type = 9
          t_popupObj.confirmFunc = lv_Vm.fn_ReportPrint

          if (FSCommUtil.gfn_isNull(this.lv_ed_cust_no)) {
            t_popupObj.confirmData = {
              fnd_cust_nm: this.lv_ed_cust_nm.value,
              rlnm_cnf_no_encr: this.lv_ed_rname_no.value1,
              bman_celph_dstno_encr: this.lv_phone_no.value1,
              bman_celph_tlnum_no_encr: this.lv_phone_no.value2,
              bman_celph_bkno_sno_encr: this.lv_phone_no.value3,
              cust_emai_addr_encr: this.lv_email_addr.value1,
              cust_emai_dm_nm: this.lv_email_addr.value2
            }
          } else {
            t_popupObj.confirmData = {
              bfcer_cust_no: this.lv_ed_cust_no
            }
          }
          t_popupObj.content = []
          // t_popupObj = {
          //   content: 'CDD 수정이 완료되었습니다.',
          // }
        }
        // 전자서식 호출
        //this.fn_ReportPrint()
      } else {
        t_popupObj = {
          content: 'CDD 수정에 실패하셨습니다.'
        }
      }

      lv_Vm.fn_AlertPopup(t_type, t_popupObj)
    },

    /******************************************************************************
     * Function명 : fn_Insert, fn_InsertResult, fn_ForceInsert
     * 설명       : CDD고객정보등록
     ******************************************************************************/
    async fn_Insert(rname_err) {
      console.log('fn_Insert....')

      // ASR240701140 / 고객명 띄어쓰기 허용
      var pObj = { objName: 'lv_cust_nm', keyName: 'value' }
      if (!this.fn_Keyup(pObj, 'I')) {
        return
      }

      // 입력/선택 필수값 검증
      if (!this.fn_SubmitVaild()) {
        return
      }

      // 등록 DATASET 초기화
      this.fn_ClearSubmitData()

      // 등록 DATASET 매핑
      this.fn_SetSubmitData('I')

      // 주민등록번호 검증오류시 강제반영 여부 확인(강제반영: 1)
      this.lv_submit_data.rname_err = rname_err

      // 서비스 호출
      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.auth = 'I'
      this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570126_S'
      this.eaiCommObj.params = this.lv_submit_data

      FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_InsertResult)
    },

    fn_InsertResult(pResultData) {
      console.log('fn_InsertResult....')
      let lv_Vm = this
      let t_type = 0
      let t_popupObj = {
        cancel: false,
        confirm: true,
        content: '',
        confirmFunc: '',
        confirmData: ''
      }
      let t_data = pResultData.data

      // CDD고객정보등록 성공 메세지 = 809901
      if (t_data.error_msg == '809901') {
        if (this.lv_isProcess) {
          t_type = 7
          t_popupObj.confirmFunc = lv_Vm.fn_ReportPrint

          if (FSCommUtil.gfn_isNull(this.lv_ed_cust_no)) {
            t_popupObj.confirmData = {
              fnd_cust_nm: this.lv_ed_cust_nm.value,
              rlnm_cnf_no_encr: this.lv_ed_rname_no.value1,
              bman_celph_dstno_encr: this.lv_phone_no.value1,
              bman_celph_tlnum_no_encr: this.lv_phone_no.value2,
              bman_celph_bkno_sno_encr: this.lv_phone_no.value3,
              cust_emai_addr_encr: this.lv_email_addr.value1,
              cust_emai_dm_nm: this.lv_email_addr.value2
            }
          } else {
            t_popupObj.confirmData = {
              bfcer_cust_no: this.lv_ed_cust_no
            }
          }
          t_popupObj.content = ['CDD 등록이 완료되었습니다. 고객등록 화면으로 이동합니다.']
          this.netxtFunc = function() {
            lv_Vm.fn_GoNextPage()
          }
        } else {
          t_type = 9
          t_popupObj.confirmFunc = lv_Vm.fn_ReportPrint

          if (FSCommUtil.gfn_isNull(this.lv_ed_cust_no)) {
            t_popupObj.confirmData = {
              fnd_cust_nm: this.lv_ed_cust_nm.value,
              rlnm_cnf_no_encr: this.lv_ed_rname_no.value1,
              bman_celph_dstno_encr: this.lv_phone_no.value1,
              bman_celph_tlnum_no_encr: this.lv_phone_no.value2,
              bman_celph_bkno_sno_encr: this.lv_phone_no.value3,
              cust_emai_addr_encr: this.lv_email_addr.value1,
              cust_emai_dm_nm: this.lv_email_addr.value2
            }
          } else {
            t_popupObj.confirmData = {
              bfcer_cust_no: this.lv_ed_cust_no
            }
          }
          t_popupObj.content = []
          // t_popupObj = {
          //   content: 'CDD 등록이 완료되었습니다.',
          // }

          // 등록 버튼 비활성화
          this.lv_btn_insert = true
          // 수정 버튼 활성화
          this.lv_btn_update = false
        }

        // 전자서식 호출
        //this.fn_ReportPrint()
      } else {
        if (FSCommUtil.gfn_isNull(t_data.dummy)) {
          // 소스 또는 쿼리 에러발생시
          t_popupObj = {
            content: 'CDD 등록에 실패하셨습니다.'
          }
        } else {
          // 주민등록번호 검증오류시
          t_popupObj = {
            confirm: true,
            confirmFunc: lv_Vm.fn_ForceInsert,
            content: "주민등록번호의 검증번호 오류입니다. 본사담당자 확인 후 진행하십시오. 강제 반영은 '확인', 다시 화면 확인하시려면 '취소'를 누르십시오. ※ 강제 반영시 수정 불가."
          }
        }
      }

      lv_Vm.fn_AlertPopup(t_type, t_popupObj)
    },

    fn_ForceInsert() {
      this.fn_Insert(1)
    },

    /******************************************************************************
     * Function명 : fn_ReportPrint
     * 설명       : 전자서식 호출
     ******************************************************************************/
    async fn_ReportPrint() {
      console.log('fn_ReportPrint....')

      let t_rlnm_cnf_no_encr = this.lv_ed_rname_no.value1 + this.lv_ed_rname_no.value2

      // 커버발행 파라미터 저장
      this.$bizUtil.fsUtils.saveCvrPblParam(this, { bfcer_cust_no: t_rlnm_cnf_no_encr }, true)

      let formList = []
      let FS000008 = { formId: 'FS000008' }

      let searchData = {
        bfcer_rlnm_no_knd_cd: this.lv_cob_rname_gb.value, // 수익증권실명번호종류코드, Length : 2
        fnd_cust_nm: this.lv_cust_nm.value, // 펀드고객명, Length : 100
        rlnm_cnf_no_encr: t_rlnm_cnf_no_encr // 실명확인번호암호화, Length : 24, 암호화여부=Y, Column=RRN_ENCR
      }

      FS000008.params = {
        reportParam: JSON.stringify(searchData),
        reportUrl: 'reportCU116500_Info'
      }
      formList.push(FS000008)

      //FSInfoUtil.commReportInfo(this, formList)
      if (this.lv_isProcess) {
        // 고객성향분석 페이지는 프로세스 일경우 팝업이라 마지막 아님
        FSInfoUtil.commReportInfo(this, formList, this.netxtFunc, false, 'MSPFS100M')
      } else {
        // 개별일 경우 해당페이지는 마지막이 됨
        this.getStore('tsStore').dispatch('IS_LAST_START')
        FSInfoUtil.commReportInfo(this, formList) // 고객성향분석 페이지는 프로세스 일경우 팝업이라 마지막 아님
      }
    },

    /******************************************************************************
     * Function명 : fn_ClearSubmitData
     * 설명       : CDD고객등록/수정시 초기화
     ******************************************************************************/
    fn_ClearSubmitData() {
      console.log('fn_ClearSubmitData....')

      // 자택주소
      this.lv_submit_data.hom_addr_pstcd01 = '' // 우편번호 앞 3자리
      this.lv_submit_data.hom_addr_pstcd02 = '' // 우편번호 나머지
      this.lv_submit_data.cust_hom_pstcd_addr = '' // 우편번호주소
      this.lv_submit_data.hom_bldg_no_encr = '' // 건물번호
      this.lv_submit_data.cust_hom_dtlad_encr = '' // 상세주소암호화
      this.lv_submit_data.hom_adtn_addr_encr = '' // 부가주소
      this.lv_submit_data.hom_addr_stdzt_yn = '' // 표준화여부

      // 자택전화번호
      this.lv_submit_data.hom_dstno_encr = ''
      this.lv_submit_data.hom_tlnum_no_encr = ''
      this.lv_submit_data.hom_bkno_sno_encr = ''

      // 직장주소
      this.lv_submit_data.jobp_addr_pstcd01 = '' // 우편번호 앞 3자리
      this.lv_submit_data.jobp_addr_pstcd02 = '' // 우편번호 나머지
      this.lv_submit_data.cust_jobp_pstcd_addr = '' // 우편번호주소
      this.lv_submit_data.jobp_bldg_no_encr = '' // 건물번호
      this.lv_submit_data.cust_jobp_dtlad_encr = '' // 상세주소암호화
      this.lv_submit_data.jobp_adtn_addr_encr = '' // 부가주소
      this.lv_submit_data.jobp_addr_stdzt_yn = '' // 표준화여부

      // 직장전화번호
      this.lv_submit_data.jobp_dstno_encr = ''
      this.lv_submit_data.jobp_tlnum_no_encr = ''
      this.lv_submit_data.jobp_bkno_sno_encr = ''

      // 국적이 한국 아닐경우 정보
      this.lv_submit_data.cust_cnf_syst_cust_eng_nm = '' // 성명 영문명
      this.lv_submit_data.cust_psprt_no_encr = '' // 여권번호
      this.lv_submit_data.cust_cnf_syst_birth_mmdd = '' // 생년월일
      this.lv_submit_data.cust_cnf_syst_sxds_cd = '' // 성별
    },

    /******************************************************************************
     * Function명 : fn_SetSubmitData
     * 설명       : CDD고객등록/수정시 데이터 매핑
     ******************************************************************************/
    fn_SetSubmitData(type) {
      console.log('fn_SetSubmitData....')

      // 조회 필드
      this.lv_submit_data.cust_cnf_syst_cust_cnf_yn = 'Y'
      this.lv_submit_data.bfcer_rlnm_no_knd_cd = this.lv_cob_rname_gb.value // 실명번호종류
      this.lv_submit_data.rlnm_cnf_no_encr = this.lv_ed_rname_no.value1 + this.lv_ed_rname_no.value2 // 실명번호

      // 성명
      this.lv_submit_data.cust_cnf_syst_cust_nm = this.lv_cust_nm.value // 성명

      // 입력/선택 필드
      this.lv_submit_data.fnd_dmcl_cntry_cd = FSCommUtil.gfn_isNull_replace(this.lv_fnd_dmcl_cntry_cd.value, 'KR') // 고객 국적
      this.lv_submit_data.bfcer_dmcl_cntry_cd = FSCommUtil.gfn_isNull_replace(this.lv_bfcer_dmcl_cntry_cd.value, 'KR') // 고객 거주국가

      this.lv_submit_data.resnt_dw_cntry_cd = FSCommUtil.gfn_isNull_replace(this.lv_submit_data.resnt_dw_cntry_cd, 'KR') // 대표자 국적
      this.lv_submit_data.agnt_naty_cntry_cd = FSCommUtil.gfn_isNull_replace(this.lv_submit_data.agnt_naty_cntry_cd, 'KR') // 대표자 거주국가

      this.lv_submit_data.bfcer_mn_ctadr_addr_cd = this.lv_addr_main_ctadr.value // 주연락처주소코드

      // 우편번호 값 나눠주는 함수 호출(공통)
      let t_pstcdObj = FSCommUtil.gfn_pstcd_substr(this.lv_addr.zipNum)

      // 직장인 경우에도 직장 컬럼이 아닌 자택 컬럼에 값 세팅해줘야 정상적으로 수정 확인됨..
      // 주소정보/전화번호정보 무조건 자택 컬럼에 값 세팅...

      // 주소
      this.lv_submit_data.hom_addr_pstcd01 = t_pstcdObj.pstcd01 // 우편번호 앞 3자리
      this.lv_submit_data.hom_addr_pstcd02 = t_pstcdObj.pstcd02 // 우편번호 나머지

      this.lv_submit_data.cust_hom_pstcd_addr = this.lv_addr.value1 // 우편번호주소
      this.lv_submit_data.hom_bldg_no_encr = this.lv_addr.value2 // 건물번호
      this.lv_submit_data.cust_hom_dtlad_encr = this.lv_addr.value3 // 상세주소암호화
      this.lv_submit_data.hom_adtn_addr_encr = this.lv_addr.value4 // 부가주소
      this.lv_submit_data.hom_addr_stdzt_yn = this.lv_addr.standard // 표준화여부

      // 전화번호
      this.lv_submit_data.hom_dstno_encr = this.lv_tel_no.value1
      this.lv_submit_data.hom_tlnum_no_encr = this.lv_tel_no.value2
      this.lv_submit_data.hom_bkno_sno_encr = this.lv_tel_no.value3

      // 휴대폰
      this.lv_submit_data.bman_celph_dstno_encr = FSCommUtil.gfn_isNull_replace(this.lv_phone_no.value1, '010')
      this.lv_submit_data.bman_celph_tlnum_no_encr = this.lv_phone_no.value2
      this.lv_submit_data.bman_celph_bkno_sno_encr = this.lv_phone_no.value3

      // email
      this.lv_submit_data.cust_emai_addr_encr = this.lv_email_addr.value1
      this.lv_submit_data.cust_emai_dm_nm = this.lv_email_addr.value2

      // 계좌신규목적
      this.lv_submit_data.bfcer_accn_new_pps_cd = this.lv_accn_new_pps_cd.value

      // 입출금거래목적
      this.lv_submit_data.bfcer_rdamt_dl_pps_cd = this.lv_rdamt_dl_pps_cd.value

      // 업종
      this.lv_submit_data.bfcer_indcl_cd = this.lv_ed_indcl_sort_cd.value

      // 직업구분
      this.lv_submit_data.bfcer_job_sc_cd = this.lv_cob_job_gb.value

      // 회사명
      this.lv_submit_data.cust_cnf_syst_co_nm = this.lv_job_name.value

      // 본인여부(화면에서 확인불가)
      this.lv_submit_data.self_yn = FSCommUtil.gfn_isNull_replace(this.lv_submit_data.self_yn, 'Y')

      // 위임내용(화면에서 확인불가)
      this.lv_submit_data.trst_cntnt = FSCommUtil.gfn_isNull_replace(this.lv_submit_data.trst_cntnt, '매매')

      // 고객 국적 한국이 아닐경우 해당 값 세팅
      if (this.lv_submit_data.fnd_dmcl_cntry_cd != 'KR') {
        this.lv_submit_data.cust_cnf_syst_cust_eng_nm = this.lv_cust_nm_eng.value // 성명 영문명
        this.lv_submit_data.cust_psprt_no_encr = this.lv_passport.value // 여권번호
        this.lv_submit_data.cust_cnf_syst_birth_mmdd = this.lv_cal_birth_dt.value.replace(/-/g, '') // 생년월일
        this.lv_submit_data.cust_cnf_syst_sxds_cd = this.lv_gender.value // 성별
      }

      // CDD고객등록시 수익증권 종업원수 쿼리에러 발생으로 추가
      if (type == 'I') {
        this.lv_submit_data.bfcer_empl_fgr = ''
      }
    },

    /******************************************************************************
     * Function명 : fn_GoNextPage
     * 설명       : 고객정보등록 페이지로 이동
     ******************************************************************************/
    fn_GoNextPage() {
      if (this.lv_call_screen_id == 'MSPFS307M') {
        // 계좌신규 추가 로직
        this.$router.push({
          name: 'MSPFS307M',
          params: {
            ed_group_no: this.lv_ed_group_no
          }
        })
      } else {
        // MSPFS101M 원래 로직
        this.$router.push({
          name: 'MSPFS101M',
          params: {
            cob_rname_gb: this.lv_cob_rname_gb.value,
            ed_rname_no: [this.lv_ed_rname_no.value1, this.lv_ed_rname_no.value2]
          }
        })
      }
    },

    /******************************************************************************
     * Function명 : fn_SubmitVaild
     * 설명       : CDD고객등록/수정시 필수값 검증
     ******************************************************************************/
    fn_SubmitVaild() {
      let t_return = true

      if (Number(this.lv_cob_rname_gb.value) <= 6) {
        // gfn_dataSet 으로 생성된 객체만 추가
        let t_vaildList1 = [
          this.lv_cust_nm, // 고객명
          this.lv_addr_main_ctadr, // 주연락처주소코드
          this.lv_fnd_dmcl_cntry_cd, // 국적
          this.lv_bfcer_dmcl_cntry_cd // 거주국가
        ]

        if (FSCommUtil.gfn_isValidList(this, t_vaildList1, t_return)) {
          t_return = false
        }

        // 주소체크
        this.lv_addr.error = false

        if (FSCommUtil.gfn_length(this.lv_addr.zimNum) > 5) {
          if (t_return) {
            FSCommUtil.gfn_validate(this, '도로명주소로 입력하세요.(우편번호 체계오류)')
          }
          this.lv_addr.error = true
          t_return = false
        }
        if (FSCommUtil.gfn_isNull(FSCommUtil.gfn_trim(this.lv_addr.value2))) {
          if (t_return) {
            FSCommUtil.gfn_validate(this, '도로명주소로 입력하세요.(빌딩주소필수)')
          }
          this.lv_addr.error = true
          t_return = false
        }

        // 본인 전화번호 체크
        this.lv_tel_no.error = false

        if (FSCommUtil.gfn_isNull(this.lv_tel_no.value1) || FSCommUtil.gfn_isNull(this.lv_tel_no.value2) || FSCommUtil.gfn_isNull(this.lv_tel_no.value3)) {
          if (t_return) {
            FSCommUtil.gfn_validate(this, '필수입력 항목 중 미입력 항목이 있습니다.')

            if (FSCommUtil.gfn_isNull(this.lv_tel_no.value1)) {
              this.$refs['tel_no1'].focus()
            } else if (FSCommUtil.gfn_isNull(this.lv_tel_no.value2)) {
              this.$refs['tel_no2'].focus()
            } else {
              this.$refs['tel_no3'].focus()
            }
          }
          this.lv_tel_no.error = true
          t_return = false
        }

        // gfn_dataSet 으로 생성된 객체만 추가
        let t_vaildList2 = [
          this.lv_accn_new_pps_cd, // 계좌신규목적
          this.lv_rdamt_dl_pps_cd, // 입출금거래목적
          this.lv_ed_indcl_sort_cd, // 업종
          this.lv_cob_job_gb // 직업구분
        ]

        if (FSCommUtil.gfn_isValidList(this, t_vaildList2, t_return)) {
          t_return = false
        }

        // 휴대폰번호
        // if (this.lv_phone_no.value1 < 0) {
        // this.gfn_getMessage("WARN", "휴대폰 앞번호를 선택하세요.");
        // return false
        // }

        // 직업구분이 (급여소득자)일 경우 회사명 체크
        if (this.lv_cob_job_gb.value == '01') {
          if (FSCommUtil.gfn_isValidList(this, [this.lv_job_name], t_return)) {
            t_return = false
          }
        } else {
          this.lv_job_name.error = false
        }

        // 고객 국적이 한국이 아닐경우
        if (this.lv_fnd_dmcl_cntry_cd.value != 'KR') {
          let t_validList2 = [
            this.lv_cust_nm_eng, // 성명 영문명
            this.lv_passport, // 여권번호
            this.lv_cal_birth_dt, // 생년월일
            this.lv_gender // 성별
          ]

          if (FSCommUtil.gfn_isValidList(this, t_validList2, t_return)) {
            t_return = false
          }
        }
        return t_return
      }
    },

    fn_clear(param) {
      switch (param) {
        case 'name':
          this.lv_cust_nm.error = false // 성명 validation 초기화
          break
        case 'number':
          this.lv_tel_no.error = false // 전화번호 validation 초기화
          break
        case 'job':
          this.lv_job_name.error = false // 회사명 validation 초기화
          break
      }
    }
  }
}
</script>
<style scoped></style>
